// ==========================================================================
// Layout
// Layout rules divide the page into sections. Layouts hold one or more
// modules together.
// ==========================================================================
.l-page {

}

.l-header {
  position: sticky;
  top: 0;
  z-index: 999;

  @include above($desktop-sm) {
    width: 240px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
  }

  @include above($desktop-xl) {
    width: 315px;
  }
}

.l-main {
  @include above($desktop-sm) {
    width: calc(100% - 240px);
    margin-left: 240px;
    position: relative;
    min-height: 100vh;
  }

  @include above($desktop-xl) {
    width: calc(100% - 315px);
    margin-left: 315px;
  }
}


// .l-main::before {
//   @include above($desktop-sm) {
//     content: '';
//     position: fixed;
//     top: 0;
//     right: 0;
//     width: calc(100% - 240px);
//     height: 100vh;
//     background-color: #7E8D98;
//     background-image: linear-gradient(144deg, #7E8D98 0%, #4A4D58 100%);
//   }

//   @include above($desktop-xl) {
//     width: calc(100% - 315px);
//   }
// }

#scroll-box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  margin-top: 87px;

  @include above($desktop-sm) {
    margin-top: 0;
  }
}

/* TODO: Optimize transition */
.background-enter {
  //transform: translateX(100%);
  position: absolute !important;
  top: 0;
  opacity: 0;
  z-index: 1;
  //display: none;
}
.background-enter.background-enter-active {
  //transform: translateX(0);
  opacity: 1;
  transition: all 600ms linear;
  //display: block;
}
.background-exit {
  //transform: translateX(0);
  opacity: 1;
  position: absolute !important;
  top: 0;
  z-index: 0;
  //display: block;
}
.background-exit.background-exit-active {
  //transform: translateX(-100%);
  opacity: 0;
  transition: all 600ms linear;
  //display: none;
}
