
.article.faq {

  .article__header {
    height: auto;
    display: block;

    &::before {
      display: none;
    }
  }

  .article__title {
    text-align: left;
    color: #06080B;
    max-width: 100%;
  }

}
